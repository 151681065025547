const state = {
  isInPreview: false,
  isTablet: /(tablet|ipad|playbook|silk|kindle)/i.test(navigator.userAgent),
  isMobile: false,
  isIos: /iPad|iPhone|iPod/.test(navigator.userAgent),
  isPlayerOpened: false,
  isMuted: false,
  isAtcOpened: false,
  hasPlayerLoaded: false,
  hasScriptLoaded: false,
};

const mutations = {
  SET_PREVIEW(state, isInPreview) {
    state.isInPreview = isInPreview;
  },

  SET_IS_MOBILE(state, isMobile) {
    state.isMobile = isMobile;
  },

  SET_SHOW_MAIN_PLAYER(state, isMainPlayerOpened) {
    state.isMainPlayerOpened = isMainPlayerOpened;
  },

  TOGGLE_PLAYER(state, isPlayerOpened) {
    state.isPlayerOpened = isPlayerOpened;
  },

  TOGGLE_MUTE(state, isMuted) {
    state.isMuted = isMuted;
  },
  TOGGLE_ATC(state, isAtcOpened) {
    state.isAtcOpened = isAtcOpened;
  },
  SET_HAS_PLAYER_LOADED(state, hasPlayerLoaded) {
    state.hasPlayerLoaded = hasPlayerLoaded;
  },

  SET_SCRIPT_LOADED(state, hasScriptLoaded) {
    state.hasScriptLoaded = hasScriptLoaded;
  }
};

const getters = {
  isInPreview: (state) => state.isInPreview,
  isMobile: (state) => state.isMobile,
  isTablet: (state) => state.isTablet,
  isPlayerOpened: (state) => state.isPlayerOpened,
  isIos: (state) => state.isIos,
  isMuted: (state) => state.isMuted,
  isAtcOpened: (state) => state.isAtcOpened,
  hasPlayerLoaded: (state) => state.hasPlayerLoaded,
  // there is no script in preview mode
  hasScriptLoaded: (state) => state.isInPreview || state.hasScriptLoaded,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};


