<template>
  <div id="app">
    <!-- If library, we don't use Vue Router because we can't have two routers -->
    <!-- We will never see the H1, because in library we will load a component -->
    <h1 v-if="isLibrary">Library</h1>
    <router-view v-else />
  </div>
</template>
<style>
body {
  position: fixed;
}
</style>
<script>
export default {
  data() {
    return {
      isLibrary: process.env.VUE_APP_LIBRARY === "true",
    };
  },
  mounted() {
    // We only need the global CSS (reset) if it's not used as library
    if (!this.isLibrary) {
      import('@/styles/app.scss')
    }
    
    const language = navigator.language.split("-")[0];
    const availableLanguages = ["en", "fr", "es", "it", "de", "nl", "no"];
    this.$i18n.locale = availableLanguages.includes(language) ? language : "en";
  },
};
</script>



<style scoped lang="scss">
#app {
  width: 100vw;
  max-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

